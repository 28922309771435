import TextReveal from "./defaults/TextReveal";
import FadeUp from "./defaults/FadeUp";

export const textRevealAnimation = () => {
	const items = [...document.querySelectorAll('[data-animation="text-reveal"]')];

	[...items].forEach((item) => {
		new TextReveal({
			element: item,
		});
	});
};

export const fadeUpAnimation = () => {
	const items = [...document.querySelectorAll('[data-animation="fade-up"]')];

	items.forEach((item) => {
		new FadeUp({
			element: item,
		});
	});
};
