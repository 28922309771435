import Lottie from "../../modules/Lottie";

export function initLottieAnimations(frontpageHero, footer) {
	if (window.frontpageHeroLottie && frontpageHero) {
		new Lottie({
			path: window.frontpageHeroLottie,
			container: frontpageHero,
			loop: true,
		});
	}

	if (window.footerLogo && footer) {
		new Lottie({
			path: window.footerLogo,
			container: footer,
			loop: true,
		});
	}
}
