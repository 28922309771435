import { gsap } from "gsap";
import SplitType from "split-type";
import Observer from "./Observer";

export default class TextReveal extends Observer {
	constructor({ element }) {
		super({ element });

		this.delay = element.getAttribute("data-delay");
		this.duration = element.getAttribute("data-duration");
		this.stagger = element.getAttribute("data-stagger");

		if (element.children.length > 0) {
			this.element = element.firstElementChild;
		} else {
			this.element = element;
		}
		const splitted = new SplitType(this.element, { types: "lines" });
		this.text = new SplitType(splitted.lines, { types: "lines, chars" });
	}

	onEnter() {
		gsap.to(this.text.chars, {
			delay: this.delay ?? 0,
			duration: this.duration ?? 1.5,
			y: "0%",
			yPercent: 0,
			stagger: this.stagger ?? 0.1,
			autoAlpha: 1,
			ease: "elastic.out(1,0.4)",
		});
	}
}
