import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function initLogoAnimations(logoSections) {
	if (!logoSections.length) return;

	logoSections.forEach((logoSection) => {
		const wordsContainers = logoSection.querySelectorAll("[data-animation='words']");
		animateWordContainers(wordsContainers, logoSection);
	});
}

function animateWordContainers(wordsContainers, trigger) {
	wordsContainers.forEach((container, index) => {
		const words = container.querySelectorAll("span");
		positionWords(words, index);
		animateWords(words, trigger);
	});
}

function positionWords(words, index) {
	const isMobile = window.innerWidth < 1024;
	const scaleValue = index === 0 ? 1 : 0;
	const yAmount = isMobile ? 50 : 100;
	const xAmount = isMobile ? 10 : 20;

	gsap.set(words[0], {
		x: -xAmount,
		y: () => -Math.abs(yAmount * Math.abs(-scaleValue)),
		rotation: -20,
	});
	gsap.set(words[1], {
		x: 50,
		y: () => -Math.abs(-yAmount * -scaleValue),
		rotation: 4,
	});
	gsap.set(words[2], {
		x: xAmount,
		y: () => -Math.abs(yAmount * -scaleValue),
		rotation: 20,
	});
}

function animateWords(words, trigger) {
	gsap.to(words, {
		x: 0,
		y: 0,
		rotation: 0,
		duration: 3,
		ease: "elastic.out(1,0.2)",
		scrollTrigger: {
			trigger: trigger,
			start: "top center+=20%",
			end: "+=1000",
		},
	});
}
