export default class VideoPlayer {
	constructor(container) {
		this.hasPlayed = false;
		this.timeline = null;
		this.container = container;
		this.playButton = container.querySelector(".video__play");
		this.videoElement = container.querySelector("video");
		this._addEventListeners();
	}

	_addEventListeners() {
		// Event listener for the play button
		this.playButton.addEventListener("click", () => {
			this.container.classList.add("video__wrapper--active");

			this.hasPlayed = true;

			if (this.videoElement.paused) {
				this.videoElement.play();

				this.playButton.style.display = "none";

				if (this.timeline) {
					this.timeline.play();
				}
			} 
		});

		this.videoElement.addEventListener("click", () => {
			this.videoElement.pause();

			this.playButton.style.display = "block";

			if (this.timeline) {
				this.timeline.reverse();
			}
		});
	}
}
