export default class CaseFilter {
	constructor() {
		this.elements = {
			tags: document.querySelectorAll(".filter__tag"),
			resetTag: document.querySelector(".filter__tag--reset"),
			caseList: document.querySelectorAll(".case"),
			filterCount: document.querySelector(".filter__count"),
			filterTotal: document.querySelector(".filter__total"),
		};

		this.selectedTags = new Set();

		if (this.elements.resetTag) {
			this.initialize();
		}
	}

	initialize() {
		this.updateTotal();
		this.addEventListeners();
		this.checkUrlParams();
	}

	addEventListeners() {
		this.elements.tags.forEach((tag) => tag.addEventListener("click", this.handleTagClick.bind(this)));
		this.elements.resetTag.addEventListener("click", this.resetFilter.bind(this));
	}

	checkUrlParams() {
		const urlParams = new URLSearchParams(window.location.search);
		const tagParam = urlParams.get("tag");

		if (tagParam) {
			const sanitizedTagParam = this.sanitizeTag(decodeURIComponent(tagParam));
			const matchingTag = Array.from(this.elements.tags).find((tag) => this.sanitizeTag(tag.innerText) === sanitizedTagParam);
			if (matchingTag) {
				this.handleTagClick({ target: matchingTag });
			} else {
				this.selectedTags.add(sanitizedTagParam);
				this.updateFilter();
			}
		}
	}

	handleTagClick(event) {
		const tagElement = event.target;
		const sanitizedTagText = this.sanitizeTag(tagElement.innerText);

		this.selectedTags.has(sanitizedTagText) ? this.selectedTags.delete(sanitizedTagText) : this.selectedTags.add(sanitizedTagText);
		tagElement.classList.toggle("filter__tag--active");

		this.updateFilter();
		this.updateUrl();
	}

	updateFilter() {
		this.toggleCaseVisibility();
		this.updateCount();
	}

	toggleCaseVisibility() {
		this.elements.caseList.forEach((item) => {
			const tags = item.dataset.tags.toLowerCase().split(",").map(this.sanitizeTag);
			const isActive = this.selectedTags.size === 0 || [...this.selectedTags].some((tag) => tags.includes(tag));
			item.classList.toggle("case--active", isActive);
		});
	}

	updateCount() {
		const activeCases = Array.from(this.elements.caseList).filter((item) => item.classList.contains("case--active"));
		this.elements.filterCount.innerText = activeCases.length;
	}

	updateTotal() {
		this.elements.filterTotal.innerText = this.elements.caseList.length;
	}

	resetFilter() {
		this.selectedTags.clear();
		this.elements.tags.forEach((tag) => tag.classList.remove("filter__tag--active"));
		this.elements.caseList.forEach((item) => item.classList.add("case--active"));
		this.updateCount();
		this.updateUrl();
	}

	updateUrl() {
		const urlParams = new URLSearchParams(window.location.search);
		if (this.selectedTags.size > 0) {
			urlParams.set('tag', encodeURIComponent([...this.selectedTags].join(',')));
		} else {
			urlParams.delete('tag');
		}
		const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
		history.pushState(null, '', newUrl);
	}

	sanitizeTag(tag) {
		// Mapping of specific tags to their stylized versions
		const tagMapping = {
			"games & interactive": "games-interactive",
			// Add more specific cases here if needed
		};

		const lowerCaseTag = tag.toLowerCase().trim();

		// Check if the tag is in the mapping, and return the mapped version
		if (tagMapping[lowerCaseTag]) {
			return tagMapping[lowerCaseTag];
		}

		// Default sanitization for tags not in the mapping
		return lowerCaseTag.replace(/[&\s]/g, '-');
	}
}
