const colors = [
	{ bright: "#edecfc", dark: "#b8b2f8" },
	{ bright: "#b7e8b8", dark: "#75cc77" },
	{ bright: "#ffd2b7", dark: "#ef8d52" },
];

const setRandomColors = () => {
	let randomIndex;
	const currentBrightColor = getComputedStyle(document.documentElement).getPropertyValue("--color-pastel").trim();

	do {
		randomIndex = Math.floor(Math.random() * colors.length);
	} while (colors[randomIndex].bright === currentBrightColor && colors.length > 1);

	const { bright, dark } = colors[randomIndex];

	document.documentElement.style.setProperty("--color-pastel", bright);
	document.documentElement.style.setProperty("--color-pastel-dark", dark);

	localStorage.setItem("lastUsedColorIndex", randomIndex);
};

const initializeColors = () => {
	const lastUsedColorIndex = localStorage.getItem("lastUsedColorIndex");

	if (lastUsedColorIndex !== null) {
		let newIndex;
		do {
			newIndex = Math.floor(Math.random() * colors.length);
		} while (newIndex === parseInt(lastUsedColorIndex) && colors.length > 1);

		const { bright, dark } = colors[newIndex];
		document.documentElement.style.setProperty("--color-pastel", bright);
		document.documentElement.style.setProperty("--color-pastel-dark", dark);
		localStorage.setItem("lastUsedColorIndex", newIndex);
	} else {
		setRandomColors();
	}
};

initializeColors();
