import WaveSurfer from "wavesurfer.js";

export default class AudioPlayer {
	constructor(container) {
		this.container = container;
		this.audioFiles = [...container.querySelectorAll("audio")];
		this.audioOutput = container.querySelector(".audio-player__output");
		this.playPauseButton = container.querySelector(".audio-player__play-pause");
		this.logo = container.querySelector("svg");
		this.audiosInfo = [];
		this.wavesurfer = WaveSurfer.create({
			container: this.audioOutput,
			waveColor: document.documentElement.style.getPropertyValue("--color-pastel-dark"),
			progressColor: "#000000",
			height: 100,
			barWidth: 4,
			barGap: 2,
			barRadius: 20,
			cursorWidth: 0,
			url: "",
		});

		this._createAudiosInfo();
		this._createPlayer();
		this._createPlaylist();
		this._addEventListeners();
	}

	_createAudiosInfo() {
		this.audioFiles.forEach((audioFile) => {
			const title = audioFile.dataset.title;
			const src = audioFile.dataset.src;
			const duration = audioFile.duration;

			this.audiosInfo.push({ title, src, duration });
		});
	}

	_createPlayer() {
		if (this.audiosInfo[0]?.src) {
			this.wavesurfer.load(this.audiosInfo[0].src);
		}
	}

	_createPlaylist() {
		const playlist = document.createElement("ul");
		playlist.classList.add("audio-player__playlist");

		this.audiosInfo.forEach((audioInfo, index) => {
			const li = document.createElement("li");
			li.classList.add("audio-player__playlist-item", "text-default", "text-sans-regular");
			li.innerText = `${index + 1}. ${audioInfo.title}`;
			li.dataset.index = index;
			playlist.appendChild(li);
		});

		this.container.appendChild(playlist);
		this.tracks = [...playlist.querySelectorAll("li")];
		this.tracks[0].classList.add("is-active");
	}

	_addEventListeners() {
		this.tracks.forEach((track) => track.addEventListener("click", this._onChangeTrack.bind(this, track)));
		this.playPauseButton.addEventListener("click", this._onPlayPauseClick.bind(this));
	}

	_onChangeTrack(track) {
		this.wavesurfer.stop();
		this.wavesurfer.load(this.audiosInfo[track.dataset.index].src);
		this.playPauseButton.classList.remove("is-playing");
		this.tracks.forEach((track) => track.classList.remove("is-active"));
		this.tracks[track.dataset.index].classList.add("is-active");
		this.wavesurfer.on("ready", () => {
			this.playPauseButton.classList.remove("is-playing");
			this._onPlayPauseClick();
		});
	}

	_onPlayPauseClick() {
		// Pause all other audio players
		document.querySelectorAll(".audio-player__play-pause.is-playing").forEach(button => {
			if (button !== this.playPauseButton) {
				button.classList.remove("is-playing");
				const playerInstance = button.closest(".audio-player").wavesurfer;
				if (playerInstance) {
					playerInstance.pause();
				}
			}
		});

		this.playPauseButton.classList.toggle("is-playing");

		if (this.playPauseButton.classList.contains("is-playing")) {
			this.wavesurfer.play();
		} else {
			this.wavesurfer.pause();
		}
	}
}
