const trigger = document.querySelector(".header__mobile-menu-trigger");
const menu = document.querySelector(".mobile-menu");

trigger.addEventListener("click", () => {
	menu.classList.toggle("mobile-menu--open");
	trigger.classList.toggle("button--light");

	if (menu.classList.contains("mobile-menu--open")) {
		trigger.textContent = "Close";
	} else {
		trigger.textContent = "Menu";
	}
});