import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

export default class Slider {
	constructor({ container }) {
		this.container = container;
		this.itemWidth = this.sliderItems = [...container.querySelectorAll(":scope > *")];
		this.slider = null;

		this._addClasses();
		this._initSlider();
	}

	_addClasses() {
		this.container.classList.add("keen-slider");
		this.sliderItems.forEach((item) => item.classList.add("keen-slider__slide"));
	}

	_initSlider() {
		this.slider = new KeenSlider(this.container, {
			centered: true,
			loop: false,
			slides: {
				perView: 2.5,
				spacing: 16,
			},
			breakpoints: {
				"(max-width: 1024px)": {
					slides: {
						perView: 1.5,
						spacing: 16,
					},
				},
			},
		});
	}
}
