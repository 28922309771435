import { initLogoAnimations } from "./logoAnimations.js";
import { initVideoAnimations } from "./videoAnimations.js";
import { initCardAnimations } from "./cardAnimations.js";
import { initSliderAnimations } from "./sliderAnimations.js";
import { initLottieAnimations } from "./lottieAnimations.js";
import { initParallaxImages } from "./parallaxImages.js";

export function initScrollAnimations() {
	const elements = {
		logoSections: document.querySelectorAll("[data-animation='logos']"),
		videos: document.querySelectorAll(".video__wrapper"),
		cards: document.querySelectorAll(".case"),
		sliders: document.querySelectorAll(".slider"),
		parallaxImages: document.querySelectorAll("[data-animation='parallax-image']"),
		frontpageHero: document.querySelector("#music-and-sound"),
		footer: document.querySelector("#footer-lottie"),
	};

	initLogoAnimations(elements.logoSections);
	initVideoAnimations(elements.videos);
	initCardAnimations(elements.cards);
	initSliderAnimations(elements.sliders);
	initLottieAnimations(elements.frontpageHero, elements.footer);
	initParallaxImages(elements.parallaxImages);
}
