let prevScrollPos = window.scrollY;
const navbar = document.querySelector(".header");

window.addEventListener("scroll", () => {
	const currentScrollPos = window.scrollY;

	if (currentScrollPos > 200) {
		if (prevScrollPos > currentScrollPos) {
			navbar.style.transform = "translateY(0%)";
		} else {
			navbar.style.transform = "translateY(-100%)";
		}
	}

	prevScrollPos = currentScrollPos;
});

document.addEventListener("DOMContentLoaded", () => {
	const pageContent = document.querySelector(".page-content");
	const firstChild = pageContent ? pageContent.firstElementChild : null;

	if (firstChild && firstChild.classList.contains("bg-pastel")) {
	}
});
