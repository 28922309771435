import lottie from "lottie-web";

export default class Lottie {
	constructor({ animationData, container, ...options }) {
		this.animationData = animationData;
		this.container = container;
		this.options = options;
		if (!this.container) return;

		this.animation = null;
		this.loop = Boolean(this.container.dataset.loop) || false;
		this.delay = this.container.dataset.delay || 0;
		this.speed = this.container.dataset.speed || 0.75;
		this.createAnimation();

		if (this.speed) {
			this.animation.setSpeed(+this.speed);
		}

		if (this.delay) {
			setTimeout(() => {
				this.animation.play();
			}, +this.delay * 1000);
		} else {
			this.animation.play();
		}
	}

	createAnimation() {
		this.animation = lottie.loadAnimation({
			container: this.container,
			renderer: "svg",
			animationData: this.animationData,
			loop: this.loop,
			paused: true,
			autoplay: false,
			...this.options,
		});
	}
}
