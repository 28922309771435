import { play } from "wavesurfer";
import WaveSurfer from "wavesurfer.js";

export default class AudioFiles {
	constructor(container) {
		this.container = container;
		this.audioFiles = [...container.querySelectorAll("audio")];
		this.output = container.querySelector(".audio-files__output");
		this.players = [];
		this._createPlayers();
		this._addEventListeners();
	}

	_createPlayers() {
		this.audioFiles.forEach((audioFile) => {
			const src = audioFile.dataset.src;
			const title = audioFile.dataset.title;

			// Create a wrapper div for each player with the required classes
			const playerDiv = document.createElement("div");
			playerDiv.classList.add("audio-files__wave");

			// Create the WaveSurfer instance
			const wavesurfer = WaveSurfer.create({
				container: playerDiv,
				waveColor: document.documentElement.style.getPropertyValue("--color-pastel-dark"),
				progressColor: "#000000",
				barWidth: 4,
				barGap: 2,
				barRadius: 20,
				cursorWidth: 3,
				height: 40,
				url: src,
			});

			// Add the .button div by default
			const buttonDiv = document.createElement("div");
			buttonDiv.classList.add("audio-files__button");
			playerDiv.appendChild(buttonDiv);

			// Append the player div to the output container
			this.output.appendChild(playerDiv);

			// Add event listener for the finish event to switch back to play button
			wavesurfer.on('finish', () => {
				buttonDiv.parentElement.classList.remove("is-playing");
			});

			this.players.push(wavesurfer);
		});
	}

	_addEventListeners() {
		this.playButtons = this.output.querySelectorAll(".audio-files__button");

		this.playButtons.forEach((button, index) => {
			button.addEventListener("click", () => {
				const playButton = button.parentElement;

				// Pause all other players and remove is-playing class
				this.players.forEach((player, playerIndex) => {
					if (playerIndex !== index) {
						player.pause();
						this.playButtons[playerIndex].parentElement.classList.remove("is-playing");
					}
				});

				// Toggle the play/pause for the clicked button
				playButton.classList.toggle("is-playing");
				this.players[index].playPause();
			});
		});
	}
}
