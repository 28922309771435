import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function initSliderAnimations(sliders) {
	if (!sliders.length) return;

	sliders.forEach((slider) => {
		
	});
}
