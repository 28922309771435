import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function initVideoAnimations(videos) {
	if (!videos.length) return;

	videos.forEach((video) => {
	});
}
